import React from "react";
import "../../Styles/T3/T3BeforeStart.css";
import india_logo from "../../Assets/t3_india_img.png";
import pakistan_logo from "../../Assets/t3_pakistan_img.png";
import { useSearchParams } from "react-router-dom";
import { useState } from "react";
import socketIOClient from "socket.io-client";
import { useEffect } from "react";
import team_default_logo from "../../Assets/t3_team_default_img.png";
import MatchSummary from "../T1/MatchSummary";
import T3MatchSummary from "./T3MatchSummary";
import he from "he";
import { getDecryptedMatchId } from "../../DecryptingMatchId";


function T3InningsBreak() {
  const ENDPOINT = process.env.REACT_APP_SOCKET_URL;
  let [searchParams] = useSearchParams();
  const [match_id, setMatch_Id] = useState("");
 

  useEffect(() => {
    const fetchMatchId = async () => {
      const searchParams = new URLSearchParams(window.location.search);
      const decryptedMatchId = await getDecryptedMatchId(searchParams);
      setMatch_Id(decryptedMatchId);
    };

    fetchMatchId();
  }, []);
  const [teamName, setTeamName] = useState({
    teama_name: "",
    teama_short_name: "",
    teama_logo: "",
    teamb_name: "",
    teamb_short_name: "",
    teamb_logo: "",
    result: "",
    total_over: "",
    target: "",
    curr_over: "",
    curr_inning: "",
    status:""
  });

  useEffect(() => {
    const socket = socketIOClient(ENDPOINT);
    socket.on("connected", (data) => {
      // console.log("connected");
    });
    socket.on("live_score_new", (data) => {
      // console.log(data);

      let mainData = data;
      setTeamName({
        teama_name: he.decode(mainData.TEAM_A.TEAM_NAME),
        teama_logo: mainData.TEAM_A.IMAGE_URL,
        teama_short_name: he.decode(mainData.TEAM_A.TEAM_NICK_NAME),
        teamb_name: mainData.TEAM_B.TEAM_NAME,
        teamb_short_name: he.decode(mainData.TEAM_B.TEAM_NICK_NAME),
        teamb_logo: mainData.TEAM_B.IMAGE_URL,
        total_over: mainData.TOTAL_OVER,
        target: data.BATTING.TARGET,
        result: mainData.RESULT,
        curr_over: mainData.BATTING.OVERS,
        curr_inning: mainData.CURRENT_INNING,
        status: mainData.STATUS
      });
    });
    socket.emit("join", "match_id", match_id);
    socket.emit("get_score", match_id);

    return () => socket.disconnect();
  }, [match_id]);
  const decodeNames = (x) => {
    // var x = "http\\u00253A\\u00252F\\u00252Fexample.com";
    if (x) {
      var r = /\\u([\d\w]{4})/gi;
      x = x.replace(r, function (match, grp) {
        return String.fromCharCode(parseInt(grp, 16));
      });
    }

    return x;
  };
  const maxLetterCount = 20; 

  const teamname = document.getElementById("t3_break_team_name");
  if (teamname) {
    if (teamname.textContent.length > maxLetterCount) {
      const truncatedText = teamname.textContent.substring(0, maxLetterCount);
      teamname.textContent = truncatedText + "...";
    }
  }
   useEffect(() => {
    teamName.status === "Break" &&
      localStorage.setItem("LOCALSTATUS", "BREAK");
      teamName.status === "Live" &&
      localStorage.setItem("LOCALSTATUS", "Live");
  }, [teamName.status]);
  return (
    <>
      {/* {teamName.curr_inning === 1 ||
      (teamName.curr_inning === 2 && teamName.curr_over === 0) ? ( */}
      {/* <div id="t3_start_white_bg">
          <div id="t3_start_teama">
            <img
              src={process.env.REACT_APP_API_URL + teamName.teama_logo}
              alt="teama_logo"
              onError={(e) => (e.target.src = team_default_logo)}
            />
          </div>{" "}
          <div id="t3_start_teama_name">
            {he.decode(decodeNames(teamName.teama_short_name))}
          </div>
          <div id="t3_before_info">
            <span id="t3_break_team_name">
              {he.decode(decodeNames(teamName.teamb_short_name + "  "))}
            </span>
            <span className="ps-1">
              {" " + "need"} {teamName.target} runs in {teamName.total_over}{" "}
              overs
            </span>
          </div>
          <div id="t3_start_teamb_name">
            {he.decode(decodeNames(teamName.teamb_short_name))}
          </div>
          <div id="t3_start_teamb">
            <img
              src={process.env.REACT_APP_API_URL + teamName.teamb_logo}
              alt="pak_logo"
              onError={(e) => (e.target.src = team_default_logo)}
            />
          </div>
          <div id="t3_before_text_bg">
            <div id="t3_before_vs_text">VS</div>
          </div>
        </div> */}
      {/* ) : (
        ""
      )} */}
      {/* <h1>{localStorage.getItem("LOCALSTATUS")}</h1> */}
      <div id="t3_start_white_bg1" >
        <div id="before_start_teama">
          <div>
            <img
              id="t3_teama_start_logo"
              src={process.env.REACT_APP_API_URL + teamName.teama_logo}
              alt="teama_logo"
              onError={(e) => (e.target.src = team_default_logo)}
            />
          </div>

          <div> {he.decode(decodeNames(teamName.teama_short_name))}</div>
        </div>
        <div id="before_start_text">
          {" "}
          {he.decode(decodeNames(teamName.teamb_short_name + "  "))}
          {" " + "need"} {teamName.target} runs in {teamName.total_over} overs
        </div>
        <div id="before_start_teamb">
          <div>{he.decode(teamName.teamb_short_name)}</div>
          <div>
            <img
              id="t3_teama_start_logo"
              src={process.env.REACT_APP_API_URL + teamName.teamb_logo}
              alt="pak_logo"
              onError={(e) => (e.target.src = team_default_logo)}
            />
          </div>
        </div>
        <div id="t3_before_text_bg">
          <div id="t3_before_vs_text">VS</div>
        </div>
      </div>
    </>
  );
}

export default T3InningsBreak;
