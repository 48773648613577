import React from "react";
import "../../Styles/T3/T3BeforeStart.css";
import india_logo from "../../Assets/t3_india_img.png";
import pakistan_logo from "../../Assets/t3_pakistan_img.png";
import { useSearchParams } from "react-router-dom";
import { useState } from "react";
import socketIOClient from "socket.io-client";
import { useEffect } from "react";
import team_default_logo from "../../Assets/t3_team_default_img.png";
import he from "he";
import { getDecryptedMatchId } from "../../DecryptingMatchId";

function T3BeforeStart() {
  const ENDPOINT = process.env.REACT_APP_SOCKET_URL;
  let [searchParams] = useSearchParams();
  const [match_id, setMatch_Id] = useState("");

  useEffect(() => {
    const fetchMatchId = async () => {
      const searchParams = new URLSearchParams(window.location.search);
      const decryptedMatchId = await getDecryptedMatchId(searchParams);
      setMatch_Id(decryptedMatchId);
    };

    fetchMatchId();
  }, []);
  const [teamName, setTeamName] = useState({
    teama_name: "",
    teama_short_name: "",
    teama_logo: "",
    teamb_name: "",
    teamb_short_name: "",
    teamb_logo: "",
    result: "",
    total_over: "",
  });

  useEffect(() => {
    const socket = socketIOClient(ENDPOINT);
    socket.on("connected", (data) => {
      
    });
    socket.on("live_score_new", (data) => {
    

      let mainData = data;
      setTeamName({
        teama_name: he.decode(mainData.TEAM_A.TEAM_NAME),
        teama_logo: mainData.TEAM_A.IMAGE_URL,
        teama_short_name: he.decode(mainData.TEAM_A.TEAM_NICK_NAME),
        teamb_name: he.decode(mainData.TEAM_B.TEAM_NAME),
        teamb_short_name: he.decode(mainData.TEAM_B.TEAM_NICK_NAME),
        teamb_logo: mainData.TEAM_B.IMAGE_URL,
        total_over: mainData.TOTAL_OVER,

        result: mainData.RESULT,
      });
    });
    socket.emit("join", "match_id", match_id);
    socket.emit("get_score", match_id);

    return () => socket.disconnect();
  }, [match_id]);
  return (
    <div>
  
        <div id="t3_start_white_bg1">
          <div id="before_start_teama">
            <img
              id="t3_teama_start_logo"
              src={process.env.REACT_APP_API_URL + teamName.teama_logo}
              alt="teama_logo"
              onError={(e) => (e.target.src = team_default_logo)}
            />
            <div>{he.decode(teamName.teama_short_name)}</div>
          </div>
          <div id="before_start_text">
            {" "}
            MATCH WILL START SOON - {teamName.total_over}{" "}
            {teamName.total_over === 1 ? "OVER" : "OVERS"} MATCH
          </div>
          <div id="before_start_teamb">
            <div>{he.decode(teamName.teamb_short_name)}</div>
            <img
              id="t3_teama_start_logo"
              src={process.env.REACT_APP_API_URL + teamName.teamb_logo}
              alt="pak_logo"
              onError={(e) => (e.target.src = team_default_logo)}
            />
          </div>
          <div id="t3_before_text_bg">
            <div id="t3_before_vs_text">VS</div>
          </div>
        </div>
      
    </div>
  );
}

export default T3BeforeStart;
