import React, { useEffect, useState } from "react";
import "../../Styles/T3/T3Teams.css";
import india_logo from "../../Assets/t3_india_img.png";
import pakistan_logo from "../../Assets/t3_pakistan_img.png";
import { useSearchParams } from "react-router-dom";
import socketIOClient from "socket.io-client";
import team_default_logo from "../../Assets/t3_team_default_img.png";
import T3TossBottom from "./T3TossBottom";
import he from "he";
import { getDecryptedMatchId } from "../../DecryptingMatchId";

function T3Teams() {
  const ENDPOINT = process.env.REACT_APP_SOCKET_URL;
  let [searchParams] = useSearchParams();
  const [match_id, setMatch_Id] = useState("");

  useEffect(() => {
    const fetchMatchId = async () => {
      const searchParams = new URLSearchParams(window.location.search);
      const decryptedMatchId = await getDecryptedMatchId(searchParams);
      setMatch_Id(decryptedMatchId);
    };

    fetchMatchId();
  }, []);
  const decodeNames = (x) => {
    // var x = "http\\u00253A\\u00252F\\u00252Fexample.com";
    if (x) {
      var r = /\\u([\d\w]{4})/gi;
      x = x.replace(r, function (match, grp) {
        return String.fromCharCode(parseInt(grp, 16));
      });
    }

    return x;
  };

  const [teamName, setTeamName] = useState({
    teama_name: "",
    teama_short_name: "",
    teama_logo: "",
    teamb_name: "",
    teamb_logo: "",
    teamb_short_name: "",
    team_a_players: [],
    team_b_players: [],
    teama_squad_type: [],
    teamb_squad_type: [],
    status:"",
    striker:"",
    non_striker:"",
    bowler:"",
    curr_inning:"",
  });

  const [notOutPlayers, setNotOutPlayers] = useState("");
  useEffect(() => {
    const socket = socketIOClient(ENDPOINT);
    socket.on("connected", (data) => {
      // console.log("connected");
    });
    socket.on("live_score_new", (data) => {

      let mainData = data;
      setTeamName({
        teama_name: he.decode(mainData.TEAM_A.TEAM_NAME),
        teamb_name: he.decode(mainData.TEAM_B.TEAM_NAME),
        teama_short_name: he.decode(mainData.TEAM_A.TEAM_NICK_NAME),
        teamb_short_name: he.decode(mainData.TEAM_B.TEAM_NICK_NAME),
        team_a_players: mainData.TEAM_A.TEAM_SQUAD,
        team_b_players: mainData.TEAM_B.TEAM_SQUAD,
        teama_logo: mainData.TEAM_A.IMAGE_URL,
        teamb_logo: mainData.TEAM_B.IMAGE_URL,
        status:mainData.STATUS,
        striker:mainData.STRIKER,
        non_striker: mainData.NONSTRIKER,
        bowler:mainData.BOWLER,
        curr_inning:mainData.CURRENT_INNING
      });
    });
    socket.emit("join", "match_id", match_id);
    socket.emit("get_score", match_id);

    return () => socket.disconnect();
  }, [match_id]);
  const [showTicker, setShowTicker] = useState(true);

  useEffect(() => {
    setTimeout(function () {
      setShowTicker(!showTicker);
      // setShow("none");
    }, 6000);
  }, []);
  const playerNameCount = 13; // Set the maximum letter count
  const strikerElements = document.querySelectorAll(".t3_teamb_player_name");
  strikerElements.forEach((striker) => {
    if (striker.textContent.length > playerNameCount) {
      const truncatedText = striker.textContent.substring(0, playerNameCount);
      striker.textContent = truncatedText + "...";
    }
  });
  // useEffect(() => {
  //   teamName.status === "Live" &&
  //     teamName.striker === 0 &&
  //     teamName.non_striker === 0 &&
  //     teamName.bowler === 0 &&
  //     teamName.curr_inning === 1 &&
  //     localStorage.setItem("LOCALSTATUS", "TOSS");
  //   teamName.status === "Live" &&

  //     teamName.bowler !== 0 &&
  //     localStorage.setItem("LOCALSTATUS", "Live");
  // }, [
  //   teamName.status,
  //   teamName.striker,
  //   teamName.non_striker,
  //   teamName.bowler,
  //   teamName.curr_inning,
  // ]);
  useEffect(() => {
    const timer = setTimeout(() => {
      if (
        teamName.status === "Live" &&
        teamName.striker === 0 &&
        teamName.non_striker === 0 &&
        teamName.bowler === 0 &&
        teamName.curr_inning === 1
      ) {
        localStorage.setItem("LOCALSTATUS", "TOSS");
        console.log("LOCALSTATUS set to TOSS");
      } else if (teamName.status === "Live" && teamName.bowler !== 0) {
        localStorage.setItem("LOCALSTATUS", "Live");
        console.log("LOCALSTATUS set to Live");
      }
    }, 5000); // Delay execution by 7 seconds
  
    return () => clearTimeout(timer); // Cleanup to prevent execution if dependencies change
  }, [
    teamName.status,
    teamName.striker,
    teamName.non_striker,
    teamName.bowler,
    teamName.curr_inning,
  ]);
  return (
    <>
      {showTicker ? ( 
      <div
        id="t3_teams_bg"
        className="d-flex position-relative"
        style={{ height: "100vh" }}
      >
        <div id="t3_teams_title_bg">
          <div id="t3_teams_teama_name">
            <img
              src={process.env.REACT_APP_API_URL + teamName.teama_logo}
              onError={(e) => (e.target.src = team_default_logo)}
              alt="india_logo"
            />
            <div >
              {he.decode(decodeNames(teamName.teama_short_name))}
            </div>
          </div>

          <div id="t3_teams_text">TEAMS</div>
          <div id="t3_teams_teamb_name">
            <div >
              {he.decode(decodeNames(teamName.teamb_short_name))}
            </div>
            <div id="t3_teams_teamb_img">
            <img
              src={process.env.REACT_APP_API_URL + teamName.teamb_logo}
              onError={(e) => (e.target.src = team_default_logo)}
              alt="pak_logo"
            />
          </div>
          </div>

         
        </div>
        <div id="t3_teams_blue_bg">
          <div className="row w-100" id="t3_teams_row">
            <div className="col-6">
              {teamName.team_a_players.slice(0, 11).map((players,idx) => {
                return (
                  <div id="t3_player_type_bg" key={idx}>
                    <div id="t3_teams_player_name_bg">
                      <div
                        id="t3_teams_player_name"
                        className="t3_teamb_player_name"
                      >
                        {decodeNames(he.decode(players.FULL_NAME))}
                      </div>
                    </div>
                    <div id="t3_teams_player_type_bg">
                      <div id="t3_teams_player_type">{players.SQUAD_TYPE}</div>
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="col-6 mx-0" id="t2_teams_teamb_bg">
              {teamName.team_b_players.slice(0, 11).map((players, idx) => {
                return (
                  <div id="t3_player_type_bg" key={idx}>
                    <div id="t3_teams_player_name_bg">
                      <div id="t3_teams_player_name">
                        <span
                          id="t3_teamb_players_name"
                          className="t3_teamb_player_name"
                        >
                          {decodeNames(he.decode(players.FULL_NAME))}
                        </span>
                      </div>
                    </div>
                    <div id="t3_teams_player_type_bg">
                      <div id="t3_teams_player_type">{players.SQUAD_TYPE}</div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
     ) : (
       ""
      )}
    </>
  );
}

export default T3Teams;
