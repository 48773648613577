import React, { useContext, useEffect, useState } from "react";
import "../../Styles/T3/T3Ticker.css";
import { SocketContext } from "../../socket";
import T3Four from "./T3Four";
import T3Six from "./T3Six";
import T3Out from "./T3Out";
import T3Toss from "./T3Toss";
import T3BeforeStart from "./T3BeforeStart";
import team_default_logo from "../../Assets/t3_team_default_img.png";
import T3SuperOverBottom from "./T3SuperOverTossBottom";
import T3BattingSummary from "./T3BattingSummary";
import T3PlayerInfo from "./T3PlayerInfo";
import he from "he";
import T3InningsBreak from "./T3InningsBreak";
import { getDecryptedMatchId } from "../../DecryptingMatchId";
import T3SummaryBottom from "./T3SummaryBottom";
import T3TossBottom from "./T3TossBottom";
// import InningsBreakBottom from "../T1/InningsBreakBottom";
function T3Ticker() {
  const maxLetterCount = 12;

  const striker = document.getElementById("t3_striker_name");
  if (striker) {
    if (striker.textContent.length > maxLetterCount) {
      const truncatedText = striker.textContent.substring(0, maxLetterCount);
      striker.textContent = truncatedText + "...";
    }
  }

  const nonStriker = document.getElementById("t3_non_striker_name");

  if (nonStriker) {
    if (nonStriker.textContent.length > maxLetterCount) {
      const truncatedText = nonStriker.textContent.substring(0, maxLetterCount);
      nonStriker.textContent = truncatedText + "...";
    }
  }
  const bowlerMaxCount = 12;
  const bowler = document.getElementById("t3_bowler_name");

  if (bowler) {
    if (bowler.textContent.length > bowlerMaxCount) {
      const truncatedText = bowler.textContent.substring(0, bowlerMaxCount);
      bowler.textContent = truncatedText + "...";
    }
  }
  const playerNameCount = 8;
  const strikerElements = document.querySelectorAll(".t3_player_name");
  strikerElements.forEach((striker) => {
    if (striker.textContent.length > playerNameCount) {
      const truncatedText = striker.textContent.substring(0, playerNameCount);
      striker.textContent = truncatedText + "...";
    }
  });
  const socket = useContext(SocketContext);

  const searchParams = new URLSearchParams(window.location.search);
  const [match_id, setMatch_Id] = useState("");

  useEffect(() => {
    const fetchMatchId = async () => {
      const searchParams = new URLSearchParams(window.location.search);
      const decryptedMatchId = await getDecryptedMatchId(searchParams);
      setMatch_Id(decryptedMatchId);
    };

    fetchMatchId();
  }, []);
  const [scores, setScores] = useState({
    teamA_name: "",
    teamB_name: "",
    teamA_logo: "",
    teamB_logo: "",
    striker_name: "",
    striker_runs: "",
    striker_balls: "",
    curr_runs: "",
    wickets: "",
    overs: "",
    non_striker_id: "",
    bowler_name: "",
    bowler_wickets: "",
    bowler_runs: "",
    bowler_overs: "",
    last_ball: "",
    toss_detail: "",
    result: "",
    current_inning: "",
    information: "",
    toss_decision: "",
    striker: "",
    non_striker: "",
    status: "",
    player_id: "",
    teamA_short_name: "",
    teamB_short_name: "",
    curr_overs: [],
    extras_type: "",
    bats_man: [],
    partnership: "",
    crr: "",
    rrr: "",
    extras: "",
    total_four: "",
    total_six: "",
    bowler: "",
    match_type: "",
    curr_match_id: "",
    target: "",
    req_runs: "",
    req_balls: "",
  });

  useEffect(() => {
    socket.on("connect", () => {
      console.log("connected!");
    });
    socket.on("live_score_new", (data) => {
      console.log(data);
      setScores({
        curr_match_id: data.MATCH_ID,
        wickets: data.BATTING.WICKETS,
        overs: data.BATTING.OVERS,
        bowler_name: data.BOWLER.NAME,
        bowler_runs: data.BOWLER.RUNS,
        bowler_wickets: data.BOWLER.WICKETS,
        bowler_overs: data.BOWLER.OVERS,
        curr_overs: data.CURRENT_OVER,
        curr_runs: data.BATTING.RUNS,
        teamA_name: data.BATTING.TEAM_NAME,
        teamB_name: data.BOWLING.TEAM_NAME,
        result: data.RESULT,
        last_ball: data.LAST_BALL,
        toss_detail: data.TOSS,
        information: data.INFORMATION,
        toss_decision: data.TOSS.TOSS_DECISION,
        striker: data.STRIKER,
        non_striker: data.NONSTRIKER,
        current_inning: data.CURRENT_INNING,
        status: data.STATUS,
        player_id: data.STRIKER.PLAYER_ID,
        teamA_short_name: he.decode(data.BATTING.TEAM_NICK_NAME),
        teamB_short_name: he.decode(data.BOWLING.TEAM_NICK_NAME),
        teamA_logo: data.BATTING.IMAGE_URL,
        teamB_logo: data.BOWLING.IMAGE_URL,
        bats_man: data.BATSMAN,
        partnership: data.PARTNERSHIP,
        crr: data.BATTING.CRR,
        extras: data.BATTING.EXTRAS,
        rrr: data.BATTING.RRR,
        total_four: data.BATTING.TOTAL_FOUR,
        total_six: data.BATTING.TOTAL_SIX,
        bowler: data.BOWLER,
        match_type: data.TYPE,
        target: data.TEAM_B.TARGET,
        req_balls: data.REMAINING_BALLS,
        req_runs: data.REQUIRED_RUNS,
      });
    });
    socket.emit("join", "match_id_", match_id);
    socket.emit("get_score", match_id);
  }, [match_id]);
  const [ballText, setBallText] = useState();
  // const imageUrl = {image}; // Replace with your image URL
  const timeoutSecs = 6;

  const [four, setFour] = useState();
  const [show, setShow] = useState();
  const [partner, setPartner] = useState();
  const [totalFour, setTotalFour] = useState();
  const [totalSix, setTotalSix] = useState(0);
  const [hasShownSummaries, setHasShownSummaries] = useState(false);

  const updateBallText = () => {
    socket.on("four", () => {
      // console.log("four");
      setFour(true);
      setShow(false);
      setTotalFour(true);
      const newBallText = <T3Four />;
      setBallText(newBallText);

      setTimeout(() => {
        setBallText("");

        setFour(false);

        setShow(true);
      }, timeoutSecs * 1000);
    });
    socket.on("six", () => {
      // console.log("six");
      setFour(true);
      setShow(false);
      setTotalFour(true);
      const newBallText = <T3Six />;
      setBallText(newBallText);
      // console.log(newBallText, "jk");
      setTimeout(() => {
        setBallText("");
        setFour(false);

        setShow(true);
      }, timeoutSecs * 1000);
    });
    socket.on("wicket", ({}) => {
      // console.log("out");

      const newBallText = <T3Out />;
      setBallText(newBallText);
      // console.log(newBallText, "jk");
      setTimeout(() => {
        setBallText("");
      }, timeoutSecs * 1000);
    });
  };

  useEffect(() => {
    updateBallText();
  }, []);

  useEffect(() => {
    if (
      scores.partnership.TOTAL_RUNS === 20 ||
      scores.partnership.TOTAL_RUNS === 30 ||
      scores.partnership.TOTAL_RUNS === 40 ||
      scores.partnership.TOTAL_RUNS === 50 ||
      scores.partnership.TOTAL_RUNS === 60 ||
      scores.partnership.TOTAL_RUNS === 70 ||
      scores.partnership.TOTAL_RUNS === 80 ||
      scores.partnership.TOTAL_RUNS === 90 ||
      scores.partnership.TOTAL_RUNS === 100 ||
      scores.partnership.TOTAL_RUNS === 120 ||
      scores.partnership.TOTAL_RUNS === 150 ||
      scores.partnership.TOTAL_RUNS === 175 ||
      scores.partnership.TOTAL_RUNS === 200 ||
      scores.partnership.TOTAL_RUNS === 230 ||
      scores.partnership.TOTAL_RUNS === 250
    ) {
      setPartner(true);

      const timeoutId = setTimeout(() => {
        setPartner(false);
      }, 6000);
      return () => clearTimeout(timeoutId);
    }

  }, [scores.partnership.TOTAL_RUNS]);

  useEffect(() => {
    if (four && partner) {
      const timeoutId = setTimeout(() => {
        setFour(false);
        setShow(true);
      }, 6000);

      return () => clearTimeout(timeoutId);
    }
  }, [four, partner]);

  const decodeNames = (x) => {
    if (x) {
      var r = /\\u([\d\w]{4})/gi;
      x = x.replace(r, function (match, grp) {
        return String.fromCharCode(parseInt(grp, 16));
      });
    }

    return x;
  };
  const [showTicker, setShowTicker] = useState();

  useEffect(() => {
    setTimeout(function () {
      setShowTicker(!showTicker);
    }, 6000);
  }, []);
  const [showDiv1, setShowDiv1] = useState(true);
  const [showDiv2, setShowDiv2] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      setShowDiv1((prevShowDiv1) => !prevShowDiv1);
      setShowDiv2((prevShowDiv2) => !prevShowDiv2);
    }, 8000);
    return () => {
      clearInterval(interval);
    };
  
  }, []);

  const handlePlayers = () => {
    return (
      <div
        id="t3_content"
        // className={`box1 ${showDiv2 ? "visible" : "hidden"}`}
      >
        {scores.current_inning === 1 ? (
          showDiv1 ? (
            <div id="t3_team_info_bg">
              TOSS
              <div id="t3_toss_team_name">
                {scores.toss_detail.SHORT_TEAM_NAME}
              </div>
            </div>
          ) : (
            showDiv2 && (
              <div id="t3_team_info_bg">
                CRR
                <div id="t3_toss_team_name">
                  {scores.crr === "-" ? "0.00" : scores.crr}
                </div>
              </div>
            )
          )
        ) : (
          ""
        )}
        {scores.current_inning === 2 ? (
          showDiv1 ? (
            <div id="t3_need_runs_info" className="">
              <div className="">
                Need
                <span id="t3_target_runs"> {scores.req_runs}</span> Runs
              </div>
              <div>
                from
                <span id="t3_target_runs"> {scores.req_balls}</span> balls
              </div>
            </div>
          ) : (
            showDiv2 && (
              <div id="t3_need_runs_info">
                <div>
                  CRR:{" "}
                  <span id="t3_target_runs">
                    {" "}
                    {scores.crr === "-" ? "0.00" : scores.crr}
                  </span>
                </div>
                <div>
                  RRR: <span id="t3_target_runs">{scores.rrr}</span>
                </div>
              </div>
            )
          )
        ) : (
          ""
        )}

        <div id="t3_players">
          {" "}
          {scores.bats_man[0] === undefined ? (
            ""
          ) : scores.bats_man[0].IS_STRIKER === "1" ? (
            <div id="t3_current_striker">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="8"
                height="15"
                viewBox="0 0 8 15"
                fill="none"
              >
                <path
                  d="M0.0498047 15L-0.00416182 0.76362L7.04561 7.85446L0.0498047 15Z"
                  fill="#E35C2A"
                />
              </svg>
            </div>
          ) : (
            ""
          )}
          <div id="t3_striker">
            <div id="t3_striker_name">
              {scores.bats_man[0] === undefined
                ? ""
                : decodeNames(scores.bats_man[0].FULL_NAME.toLowerCase())}
            </div>
            <div className="d-flex" id="t3_striker_runs_balls">
              <span style={{ fontWeight: "500" }} id="t3_striker_runs">
                {scores.bats_man[0] === undefined
                  ? ""
                  : scores.bats_man[0].RUNS}
              </span>
              <span style={{ fontWeight: "400" }} id="t3_striker_balls">
                {scores.bats_man[0] === undefined
                  ? ""
                  : scores.bats_man[0].BALLS}
              </span>
            </div>
          </div>
          {scores.bats_man[1] === undefined ? (
            ""
          ) : scores.bats_man[1].IS_STRIKER === "1" ? (
            <div id="t3_current_non_striker">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="8"
                height="15"
                viewBox="0 0 8 15"
                fill="none"
              >
                <path
                  d="M0.0498047 15L-0.00416182 0.76362L7.04561 7.85446L0.0498047 15Z"
                  fill="#E35C2A"
                />
              </svg>
            </div>
          ) : (
            ""
          )}
          <div id="t3_non_striker">
            <div id="t3_non_striker_name">
              {scores.bats_man[1] === undefined
                ? ""
                : decodeNames(scores.bats_man[1].FULL_NAME).toLowerCase()}
            </div>
            <div className="d-flex" id="t3_striker_runs_balls">
              <span style={{ fontWeight: "500" }} id="t3_striker_runs">
                {scores.bats_man[1] === undefined
                  ? ""
                  : scores.bats_man[1].RUNS}
              </span>
              <span style={{ fontWeight: "400" }} id="t3_striker_balls">
                {scores.bats_man[1] === undefined
                  ? ""
                  : scores.bats_man[1].BALLS}
              </span>
            </div>
          </div>
        </div>
        <div id="t3_bowler_data">
          <div id="t3_bowler_details">
            <span id="t3_bowler_name">{decodeNames(scores.bowler.NAME)}</span>
          </div>{" "}
          <span id="t3_bowler_runs_wickets">
            {scores.bowler.WICKETS}
            {scores.bowler === 0 ? "" : "-"}
            {scores.bowler.RUNS}{" "}
            <span id="t3_bowler_overs">{scores.bowler.OVERS}</span>
          </span>
          <div
            className="d-flex"
            id="t3_curr_overs"
            style={{
              direction: "rtl",
              transform: "rotate(-360deg)",
              flexDirection: "row-reverse",
              justifyContent: "left",
            }}
          >
            {scores.bowler === 0 ? (
              ""
            ) : (
              <>
                {" "}
                <div id="t3_single_balls"></div>
                <div id="t3_single_balls"></div>
                <div id="t3_single_balls"></div>
                <div id="t3_single_balls"></div>
                <div id="t3_single_balls"></div>
                <div id="t3_single_balls"></div>
              </>
            )}
          </div>
          {showDiv1 &&
          (scores.bowler.BALLS === "0.0" ||
            scores.bowler.BALLS === "1.0" ||
            scores.bowler.BALLS === "2.0" ||
            scores.bowler.BALLS === "3.0" ||
            scores.bowler.BALLS === "4.0" ||
            scores.bowler.BALLS === "5.0" ||
            scores.bowler.BALLS === "6.0" ||
            scores.bowler.BALLS === "7.0" ||
            scores.bowler.BALLS === "8.0" ||
            scores.bowler.BALLS === "9.0" ||
            scores.bowler.BALLS === "10.0" ||
            scores.bowler.BALLS === "11.0" ||
            scores.bowler.BALLS === "12.0" ||
            scores.bowler.BALLS === "13.0" ||
            scores.bowler.BALLS === "14.0" ||
            scores.bowler.BALLS === "15.0" ||
            scores.bowler.BALLS === "16.0" ||
            scores.bowler.BALLS === "17.0" ||
            scores.bowler.BALLS === "18.0" ||
            scores.bowler.BALLS === "19.0") ? (
            ""
          ) : (
            <div
              className="d-flex"
              id="t3_curr_overs"
              style={{
                direction: "rtl",
                transform: "rotate(-360deg)",
                flexDirection: "row-reverse",
                justifyContent: "left",
              }}
            >
              {reversedOvers.map((overs, index) => (
                <div key={index} style={{ transform: "rotate(-360deg)" }}>
                  {overs.RUNS === 4 &&
                  overs.WICKET === 0 &&
                  overs.EXTRAS === 0 ? (
                    <div id="t3_single_balls_six">{overs.TOTAL_RUNS}</div>
                  ) : overs.RUNS === 6 &&
                    overs.WICKET === 0 &&
                    overs.EXTRAS === 0 ? (
                    <div id="t3_single_balls_six">{overs.TOTAL_RUNS}</div>
                  ) : overs.WICKET === 1 ? (
                    <>
                      {" "}
                      <div
                        className="d-flex flex-column align-items-center justify-content-center"
                        // style={{ background: "#D93025", color: "white" }}
                        id="t3_single_balls_six"
                      >
                        {overs.WICKET === 1 &&
                        overs.RUNS === 0 &&
                        overs.EXTRAS === 0 ? (
                          <>
                            <span

                            //   style={{
                            //     height: "4.5vh",
                            //     width: "4.5vh",
                            //     display: "flex",
                            //     alignItems: "center",
                            //     justifyContent: "center",
                            //   }}
                            //   style={{
                            //     height: "4.5vh",
                            //     width: "4.5vh",
                            //     display: "flex",
                            //     alignItems: "center",
                            //     justifyContent: "center",
                            //   }}
                            >
                              {"W"}
                            </span>
                          </>
                        ) : (
                          <>
                            <span
                              style={{
                                fontSize: "1.5vh",
                                lineHeight: "1.7vh",
                                fontWeight: "400",
                                height: "4.5vh",
                                width: "4.5vh",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              {overs.TOTAL_RUNS}
                            </span>
                            <span
                              style={{
                                fontSize: "1.5vh",
                                lineHeight: "1.7vh",
                                fontWeight: "400",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              {"W"}
                            </span>
                          </>
                        )}
                      </div>
                    </>
                  ) : (
                    <div>
                      {overs.EXTRAS !== 0 ? (
                        <div
                          id="t3_extra_balls"
                          className="d-flex flex-column "
                        >
                          <span
                            className=""
                            // style={{
                            //   fontSize: "1.3vh",
                            //   lineHeight: "1.6vh",
                            //   // height: "4.5vh",
                            //   width: "4.5vh",
                            //   display: "flex",
                            //   alignItems: "center",
                            //   justifyContent: "center",
                            // }}
                          >
                            {overs.TOTAL_RUNS}
                          </span>
                          <span
                          // className="t3_extra_balls"
                          // style={{
                          //   fontSize: "1.3vh",
                          //   lineHeight: "1.6vh",
                          //   // height: "4.5vh",
                          //   width: "4.5vh",
                          //   display: "flex",
                          //   alignItems: "center",
                          //   justifyContent: "center",
                          // }}
                          >
                            {overs.EXTRAS_TYPE}
                          </span>
                        </div>
                      ) : (
                        <span
                          id="t3_single_balls"
                          className="d-flex flex-column "
                          // style={{
                          //   height: "4.5vh",
                          //   width: "4.5vh",
                          //   display: "flex",
                          //   alignItems: "center",
                          //   justifyContent: "center",
                          // }}
                        >
                          {overs.RUNS}
                        </span>
                      )}
                    </div>
                  )}
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    );
  };

  const handleBoundaries = () => {
    if (totalFour && partner) {
      return (
        <div>
          {/* <div id="t3_boundaries_data" className="box">
            match BOUNDARIES - FOURS: {scores.total_four} | SIXES:{" "}
            {scores.total_six} | EXTRAS: {scores.extras.TOTAL_EXTRA}
          </div> */}

          {show && (
            <div id="t3_partnership_data">
              C. PARTNERSHIP: {scores.partnership.TOTAL_RUNS} (
              {scores.partnership.TOTAL_BALLS}) {"\u2002"}
              <span className="t3_player_name">
                {scores.striker === undefined
                  ? ""
                  : decodeNames(scores.striker.NAME)}
                {"\u2002"}
              </span>{" "}
              {scores.striker === 0 ? "" : scores.striker.RUNS} (
              {scores.striker === 0 ? "" : scores.striker.BALLS}) | {"\u2002"}
              <span className="t3_player_name">
                {" "}
                {scores.non_striker === undefined
                  ? ""
                  : decodeNames(" " + scores.non_striker.NAME)}
                {"\u2002"}
              </span>
              {scores.striker === 0 ? "" : scores.striker.RUNS} (
              {scores.non_striker === 0 ? "" : scores.non_striker.BALLS})
            </div>
          )}
        </div>
      );
    } else if (four === false && partner === true) {
      return (
        <div id="t3_partnership_data">
          C. PARTNERSHIP: {scores.partnership.TOTAL_RUNS} (
          {scores.partnership.TOTAL_BALLS}) {"\u2002"}
          {scores.striker === undefined ? "" : decodeNames(scores.striker.NAME)}
          {"\u2002"}
          {scores.striker === 0 ? "" : scores.striker.RUNS} (
          {scores.striker === 0 ? "" : scores.striker.BALLS}) |{" "}
          {scores.non_striker === undefined
            ? ""
            : decodeNames(scores.non_striker.NAME)}{" "}
          {scores.striker === 0 ? "" : scores.striker.RUNS} (
          {scores.non_striker === 0 ? "" : scores.non_striker.BALLS})
        </div>
      );
    } else if (four === true || (partner && four)) {
      return (
        <div id="t3_partnership_data">
          Innings BOUNDARIES - FOURS: {scores.total_four} | SIXES:{" "}
          {scores.total_six} | EXTRAS: {scores.extras.TOTAL_EXTRA}
        </div>
      );
    } else {
    }
  };
  function reverseArrayWithIndex(arr) {
    // Create an array of objects with original elements and indices
    const indexedArray = arr.map((element, index) => ({ element, index }));

    // Sort the array based on indices in reverse order
    const sortedArray = indexedArray.sort((a, b) => b.index - a.index);

    // Extract the original elements from the sorted array
    const reversedArray = sortedArray.map((item) => item.element);

    return reversedArray;
  }
  let reversedOvers = reverseArrayWithIndex(scores.curr_overs.slice(0, 7));

  let LOCAL_MATCH_STATUS = localStorage.getItem("LOCALSTATUS");
  return (
    <>
      {LOCAL_MATCH_STATUS === "TOSS" ? (
        <T3TossBottom />
      ) : scores.result === null && scores.information === null ? (
        <T3BeforeStart />
      ) : scores.curr_overs.length === 0 &&
        scores.striker === 0 &&
        scores.current_inning === 1 &&
        scores.match_type !== "SUPER_OVER" ? (
        <T3Toss />
      ) : scores.curr_overs.length === 0 &&
        scores.striker === 0 &&
        scores.current_inning === 1 &&
        scores.match_type === "SUPER_OVER" ? (
        <T3SuperOverBottom data={scores.match_type} />
      ) : (
        ""
      )}
      {LOCAL_MATCH_STATUS === "BREAK" && <T3InningsBreak />}

      {scores.status === "Break" ? <T3BattingSummary /> : ""}

      {scores.status === "Live" &&
      scores.current_inning === 2 &&
      scores.bowler === 0 ? (
        <T3InningsBreak />
      ) : (
        ""
      )}
      {LOCAL_MATCH_STATUS === "COMPLETED" ? <T3SummaryBottom /> : ""}

      {scores.status === "Completed" ? <T3BattingSummary /> : ""}

      {scores.striker.RUNS === 0 &&
      scores.striker.BALLS === 0 &&
      scores.overs === 0 &&
      scores.status !== "Completed" &&
      scores.status !== "Break" ? (
        showTicker ? (
          <T3PlayerInfo strikerData={scores.striker} />
        ) : (
          <div data-aos="fade-up" data-aos-delay="500"></div>
        )
      ) : (
        <div data-aos="fade-up" data-aos-delay="500"></div>
      )}

      <div>
        {scores.striker.RUNS === 0 &&
        scores.striker.BALLS === 0 &&
        scores.overs !== 0 &&
        scores.status !== "Completed" &&
        scores.status !== "Break" ? (
          <T3PlayerInfo strikerData={scores.striker} />
        ) : (
          <div data-aos="fade-up" data-aos-delay="500"></div>
        )}
      </div>
      {scores.striker.RUNS === 0 &&
      scores.striker.BALLS === 0 &&
      scores.overs === 0 &&
      scores.current_inning === 2 &&
      scores.status !== "Completed" &&
      scores.status !== "Break" ? (
        <T3PlayerInfo strikerData={scores.striker} />
      ) : (
        <div data-aos="fade-up" data-aos-delay="500"></div>
      )}
      <div id="t3_container_bg" data-aos="fade-up" data-aos-delay="500">
        {ballText}
        <div id="t3_ticker_bg">
          <div id="t3_teama_logo">
            <img
              src={process.env.REACT_APP_API_URL + scores.teamA_logo}
              alt="india_logo"
              className="bg-primary"
              onError={(e) => (e.target.src = team_default_logo)}
            />
          </div>
          <div id="t3_teama_name">{scores.teamA_short_name}</div>
          <div id="t3_match_details">
            <div id="t3_team_runs_bg">
              <div id="t3_teams_runs_wickets">
                {scores.curr_runs}-{scores.wickets}
              </div>
              <div id="t3_overs">{scores.overs} ov</div>
            </div>{" "}
          </div>

          {handlePlayers()}

          {handleBoundaries()}
          <div id="t3_teamb_logo">
            <div id="t3_teamb_name">{scores.teamB_short_name}</div>
            <img
              src={process.env.REACT_APP_API_URL + scores.teamB_logo}
              alt="t3_teamb_logo"
              className="bg-primary"
              onError={(e) => (e.target.src = team_default_logo)}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default T3Ticker;
